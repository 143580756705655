<template>
  <vs-card>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
    </vs-row>
    <div id="data-list-list-view" class="data-list-container">
      <div class="flex flex-col lg:hidden">
        <div v-for="order in users" :key="order._id" class="flex flex-col flex-wrap w-full">
          <!-- product -->
          <div class="flex flex-col justify-center">
            <div class="
                text-lg
                lg:text-2xl
                md:text-2xl
                lg:font-semibold
                md:font-semibold
                cursor-pointer
              ">
              {{ order.createdAt | date_formatter }}
            </div>
            <div class="text-lg">Order#: {{ order.orderNumber }}</div>
            <div class="
                text-lg
                lg:text-2xl
                md:text-2xl
                font-semibold
                text-primary
                lg:mt-2
                md:mt-2
              ">
              ${{ order.total | formatDecimals }}
            </div>
            <template>
              <div v-html="getStatusDisplay(order)"></div>
            </template>
            <div class="text-lg">
              Items: {{ getQuantityTotal(order.items) }}
            </div>
          </div>

          <vs-button type="border" @click="handleReorder(order._id)" color="success" class="mobileBtn my-2">Reorder</vs-button>
          <div class="flex items-center space-x-2">

            <vx-tooltip v-if="order.status === 'Failed' || order.status === 'Payment-Failed'" text="Retry Payment" position="top">
              <button @click="selectedToPay(order)" class="mobileBtn">
                Pay Now
              </button>
            </vx-tooltip>

            <a :href="getPdf(order)" :download="getName(order)" target="_blank" v-if="getStatus(order) === 'Paid'" class="mobileBtn flex-1">
              Download Invoice
            </a>

            <span v-else class="mobileBtn disabled"> Download Invoice </span>

            <vs-button type="border" size="medium" @click="viewDetailHandler(order)" icon-pack="feather" icon="icon-eye" color="success" class="mobileBtn"></vs-button>

          </div>

          <vs-divider />
        </div>
      </div>

      <vs-table class="hidden lg:block" ref="table" :sst="true" :total="totalDocs" :data="users" @search="handleSearch"
        @change-page="handleChangePage" @sort="handleSort" :max-items="dataTableParams.limit" search
        :noDataText="noDataText">
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-left items-top">
          <vs-select placeholder="10" autocomplete v-model="dataTableParams.limit">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in limitOptions" />
          </vs-select>
          <div class="ml-5 customClinicStyle">
            <Clinic :onChange="() => { }" :title="''" />
          </div>
        </div>

        <template slot="thead">
          <vs-th>Clinic Name</vs-th>
          <vs-th sort-key="orderNumber">Order Id</vs-th>
          <vs-th>No. of Orders</vs-th>
          <vs-th sort-key="createdAt">Order Date</vs-th>
          <!-- <vs-th sort-key="status">Status</vs-th> -->
          <vs-th>Total</vs-th>
          <vs-th>Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="font-bold mb-1">{{ tr.clinic.clinicName }}</p>
              <div v-for="(item, index) in tr.items" :key="index">
                <p class="text-sm">{{ `${item.quantity} x ${item.name}` }}</p>
              </div>
            </vs-td>
            <vs-td>
              <p v-for="supplierOrder in groupSupplierOrders(data[indextr].supplierOrders || [])" :key="supplierOrder._id">
                {{ supplierOrder.orderDetails.orderNumber }}
              </p>
            </vs-td>
            <vs-td>
              {{ groupSupplierOrdersLength(data[indextr].supplierOrders)}}
            </vs-td>
            <vs-td :data="data[indextr].createdAt">{{
                data[indextr].createdAt | date_formatter
            }}</vs-td>
            <!-- <vs-td :data="data[indextr].status">
              {{ getStatus(data[indextr]) }}
            </vs-td> -->
            <vs-td :data="data[indextr].total">{{ currency }}
              ${{ data[indextr].total | formatDecimals }}</vs-td>

           <vs-td :data="data[indextr]._id">
              <div class="flex flex-no-wrap items-center w-full pt-4 lg:pt-0">
                <vx-tooltip v-if="
                  data[indextr].status === 'Failed' ||
                  data[indextr].status === 'Payment-Failed'
                " text="Retry Payment" position="top">
                  <vs-button color="#3dc9b3" type="border" @click="selectedToPay(data[indextr])"
                    style="padding: 4px 10px">Pay Now</vs-button>
                </vx-tooltip>

                <vx-tooltip :text="tooltipText(data[indextr])" class="tooltipOrder">
                  <a :href="getPdf(data[indextr])" :download="getName(data[indextr])" target="_blank"
                  :class="buttonDownloadLinkClass(data[indextr])" class="vs-button small downloadLink vs-button-flat p-3 d-inline-block mx-1">
                    Download Invoice
                  </a>
                </vx-tooltip>
                <vs-button type="border" color="success" size="small" @click="handleReorder(tr._id)">Reorder</vs-button>
                <vx-tooltip text="View Order Details">
                  <vs-button style="float: left" type="border" size="small" @click="viewDetailHandler(data[indextr])"
                    icon-pack="feather" icon="icon-eye" color="success" class="m-1"></vs-button>
                </vx-tooltip>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-popup :active.sync="popUpPay" title="Pay Confirmation">
        <div class="modalContent">
            <div class="px-5 pt-4 pb-2">
                <p class="text-xl font-semibold">Are you sure you want to pay this order?</p>
                <p class="mt-2">
                  <span class="font-semibold">Order Number:</span> {{ selectedToPayData.orderNumber }}
                </p>
                <p class="mt-1">
                  <span class="font-semibold">Total Amount:</span> {{ selectedToPayData.total | formatDecimals | currency }}
                </p>
                <p class="font-semibold mt-2">Selected payment methods:</p>
            </div>
            <ul class="m-0 p-0 divide-y divide-gray-100">
              <li v-for="(card, index) in paymentMethods" :key="index">
                <div v-if="displayed(card.type)" class="flex flex-row py-2 px-5 hover:bg-gray-100">
                    <div class="pr-3 pt-1">
                      <vs-radio v-model="selectedPayment" :vs-value="
                        card.cardId ? card.cardId : card.paymentMethodId
                      " />
                    </div>
                    <div v-if="card.type === 'CreditCard'">
                      <p class="font-medium">{{ card.brand }}</p>
                      <small class="block mb-1">XXXX XXXX XXXX {{ card.last4 }}</small>
                      <small class="block">
                        {{ card.exp_month }} /
                        {{ card.exp_year }}
                      </small>
                    </div>
                    <div v-if="card.type === 'BankAccount'">
                      <p class="font-medium">{{ card.accountHolderName }}</p>
                      <small class="block mb-1">{{ card.accountHolderEmail }}</small>
                      <small class="block">
                        {{ card.bsb }} /
                        {{ card.accountNumber }}
                      </small>
                    </div>
                    <div v-if="card.type === 'payment-terms-30'">
                      <p class="font-medium">{{ card.text }}</p>
                      <small class="block">{{ card.value }}</small>
                    </div>
                  </div>
              </li>
            </ul>

            <span
              class="inline-block underline text-base font-semibold cursor-pointer mx-6 mt-4"
              @click="openPopup">
              &plus; Add Payment Method
            </span>

            <vs-popup class="holamundo" title="Add payment method" :active.sync="showAddPayment">
              <vs-tabs v-if="showCreditCard || showBankAccount">
                <vs-tab label="Credit Card" @click="paymentType = 'CreditCard'" v-if="showCreditCard">
                  <stripe-elements ref="stripeElement" v-bind:title="saveCreditCard" :pk="stripePublishableKey"
                    :amount="totalPrice" locale="en" @token="tokenCreated" @loading="loading = $event">
                  </stripe-elements>
                  <vs-button @click="submit">Confirm And Pay</vs-button>
                </vs-tab>
                <vs-tab label="Bank Account" @click="paymentType = 'ClinicBankAccount'" v-if="showBankAccount" class="custom-tab">
                  <BankSetup :totalPrice="totalPrice" :financialDetails="financialDetails"
                    :isNewClinicBankAccount="isNewClinicBankAccount" :shippingDetails="shippingDetails"
                    :paymentType="'ClinicBankAccount'" :repayment="true"
                    :clinicId="orderData.customerId"
                    @onClinicBankPaymentSuccess="clinicBankPaymentSuccess"></BankSetup>
                </vs-tab>
              </vs-tabs>
            </vs-popup>

          </div>
          <div class="flex items-center justify-between space-x-4 my-4 px-4 mt-8">
            <vs-button class="flex-1" color="primary" @click="retryOrder()">Yes, pay</vs-button>
            <vs-button class="flex-1" color="danger" @click="popUpPay = false">Not now</vs-button>
          </div>
      </vs-popup>

      <div class="mt-4 flex flex-col md:flex-row items-starts md:items-center justify-start md:justify-between space-y-3 md:space-y-0">
        <span>
          {{
              dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
          }}
          -
          {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div v-if="serverResponded">
          <paginate :page-count="totalPage" :click-handler="handleChangePage" class="pagination" :page-range="9"
            :prevText="'<'" :nextText="'>'"></paginate>
        </div>
      </div>
    </div>
    <vs-popup :active.sync="hasTCProducts" title="">
      <div class="p-5">
        <p class="text-sm lg:text-lg md:text-base">You have products that needs approval:</p>
        <div v-for="product in tcProducts" :key="product._id">
          <div class="text-base lg:text-xl md:text-lg font-semibold cursor-pointer text-pd-green">{{ product.name }}</div>
        </div>
        <PatientTC
          :onSave="onSave"
          @insertPatient="routeOrder"
          :link="'#'"
          customClass="w-full"
        />
        <vs-button
          class="item-view-secondary-action-btn bg-primary py-4 md:py-3 px-12 mt-2 lg:rounded-lg items-center justify-center text-white cursor-pointer w-full md:h-auto h-full text-base md:text-lg"
          @click="toggleReorder"
        >
          Reorder
        </vs-button>
      </div>
    </vs-popup>
  </vs-card>
</template>

<script>
import requestDoctorAssociation from "../../others/requestDoctorAssociation";
import inviteUser from "../../others/inviteUser";
import { mapActions } from "vuex";
import BankSetup from "../../order-center/BankSetup";
import StripeElements from "../../order-center/StripeElements";
import Clinic from "../../shop/clinic";
import { uniqBy } from "lodash";
import PatientTC from "../../../org-owner/ecommerce/PatientTC.vue";

export default {
  name: "ClinicOrderList",
  inject: ['getRoleRouteName'],
  components: {
    "request-doctor": requestDoctorAssociation,
    inviteUser,
    BankSetup,
    StripeElements,
    Clinic,
    PatientTC
  },
  props: {
    canDelete: {
      type: Boolean,
      default: false,
    },
    inTabEdit: {
      type: Boolean,
      default: false,
    },
    viewDetailRoute: {
      type: String,
      default: "OrderDetail",
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stripePublishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      saveCreditCard: true,
      popUpPay: false,
      noDataText: "Loading..",
      clinicCount: 0,
      customerCSVurl: false,
      isMounted: false,
      totalDocs: 0,
      currency: process.env.VUE_APP_CURRECY,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
      },
      serverResponded: false,
      limitOptions: [5, 10, 25, 50, 100],
      awaitingSearch: null,
      selectedToPayData: {
        orderNumber: "",
        total: "",
      },
      cardDetails: [],
      defaultCard: null,
      paymentType: null,
      financialDetails: null,
      hasCreditCards: false,
      users: [],
      showAddPayment: false,
      shippingDetails: null,
      token: null,
      totalPrice: null,
      isRepayment: false,
      cardId: null,
      paymentMethods: [],
      selectedPayment: null,
      showFlag: true,
      paymentDetails: null,
      orderData: null,
      pTypes: [],
      showCreditCard: false,
      showBankAccount: false,
      loading: false,
      onSave: false,
      hasTCProducts: false,
      tcProducts: []
    };
  },
  methods: {
    ...mapActions("ecommerce", [
      "fetchOrderedList",
      "fetchOrderedListAdmin",
      "retryPayment",
      "validateTCProducts",
    ]),
    ...mapActions("general", [
      "fetchUserDetails",
      "fetchCardDetails",
      "fetchCardDetailsList",
      "fetchClinicPaymentDetails",
    ]),
    ...mapActions("clinic", [
      "addNewPaymentMethod",
      "fetchClinicsDetail"
    ]),
    toggleReorder() {
      this.onSave = true;
      setTimeout(() => {
        this.onSave = false;
      }, 500);
    },
    routeOrder(){
      this.hasTCProducts = false;
      if (localStorage.getItem("orderId")){
        localStorage.removeItem("orderId")
      }
      localStorage.setItem("orderId", this.orderId);
      setTimeout(() => {
        this.$router.push({ path: "checkout"});
      }, 500)
    },
    async handleReorder(orderId){
      try {
        this.orderId = orderId;
        const res = await this.validateTCProducts({orderId});
        if (res.status == 200) {
          if (!res.data.data.length) {
            this.routeOrder();
            return;
          }
          this.tcProducts = res.data.data[0].items;
          this.hasTCProducts = true;
        }
      } catch (error) {
        console.log("ERR: ", error);
      }
    },
    groupSupplierOrdersLength(orders) {
      return this.groupSupplierOrders(orders || []).length
    },
    groupSupplierOrders(orders) {
      return uniqBy(orders, 'orderDetails.orderNumber')
    },
    setDefaultPaymentType(paymentMethods) {
      const defaultValue = paymentMethods.filter(({ isDefault }) => isDefault);
      if (defaultValue.length) {
        this.selectedPayment = defaultValue[0].cardId
          ? defaultValue[0].cardId
          : defaultValue[0].paymentMethodId;

        console.log(this.selectedPayment);
      }
    },
    async getClinicPaymentDetails(clinicId) {
      this.fetchClinicPaymentDetails(clinicId)
        .then(async (res) => {
          this.paymentMethods = res.data.data;

          const defaultValue = res.data.data.filter(
            ({ isDefault }) => isDefault
          );

          if (defaultValue.length) {
            this.selectedPayment = defaultValue[0].cardId
              ? defaultValue[0].cardId
              : defaultValue[0].paymentMethodId;
          }

          this.totalPrice = this.orderData.total;
          this.popUpPay = true;
          this.showFlag = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getQuantityTotal(data) {
      return data.reduce((a, b) => a + (b["quantity"] || 0), 0);
    },
    clinicBankPaymentSuccess(val) {
      this.paymentType = "ClinicBankAccount";
      setTimeout(() => {
        this.getClinicPaymentDetails(this.orderData.customerId)
        this.retryOrder(val);
      }, 500);
    },
    tokenCreated(token) {
      this.addNewPaymentMethod({
        token,
        clinicId: this.orderData.customerId,
        intentId: null,
      })
        .then((res) => {
          this.$vs.loading.close();
          this.showAddPayment = false;
          this.getClinicPaymentDetails(this.orderData.customerId);
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err);
        });
      // this.retryOrder({
      //   source: token.id,
      //   amount: this.totalPrice, // the amount you want to charge the customer in cents. $100 is 1000 (it is strongly recommended you use a product id and quantity and get calculate this on the backend to avoid people manipulating the cost)
      // });
    },
    tooltipText(data){
      const isDownload = data && data.orderinvoices && data.orderinvoices.pdfLink && data.status === 'Paid'
      return isDownload ? 'Invoice PDF' : 'Not ready'

    },
    buttonDownloadLinkClass(data){
      const isDownload = data && data.orderinvoices && data.orderinvoices.pdfLink && data.status === 'Paid'
      return isDownload ? 'vs-button-success' : 'disabled'
    },
    submit() {
      this.$refs.stripeElement.submit();
      this.paymentType = "CreditCard";
    },
    async getUserDetail() {
      const userDetail = await this.fetchUserDetails();
      if (
        userDetail.data.data.paymentDetails &&
        userDetail.data.data.paymentDetails.cardId
      ) {
        const cardDetails = await this.fetchCardDetails();
        this.defaultCard = cardDetails.data.data;
      }
      const cardDetailsList = await this.fetchCardDetailsList();
      if (
        cardDetailsList.data.data.length > 0 &&
        userDetail.data.data.paymentDetails.cardId
      ) {
        this.hasCreditCards = true;
        this.paymentType = "SavedCard";
      }
      this.cardDetails = cardDetailsList.data.data;
      this.isAccessPaymentTerms =
        typeof userDetail.data.data.isAccessPaymentTerms !== "undefined"
          ? userDetail.data.data.isAccessPaymentTerms
          : false;

      if (userDetail.data.data.financialDetails) {
        this.financialDetails = userDetail.data.data.financialDetails;
        this.isNewClinicBankAccount = false;
      }

      return this.formatUserDetailResponse(userDetail.data.data);
    },
    openPopup() {
      this.popUpPay = false;
      this.showAddPayment = true;
      this.paymentType = "CreditCard"
    },
    formatUserDetailResponse(userDetail) {
      return {
        clinicName: userDetail.clinicName,
        address: userDetail.address,
        email: userDetail.email,
      };
    },
    getPdf(row) {
      if (row.orderinvoices) {
        return row.orderinvoices.pdfLink;
      }
      return "#";
    },
    getName(row) {
      if (row.orderinvoices) {
        const companyName = row.shippingDetails.companyName
          ? row.shippingDetails.companyName
          : "NA";
        return `${row.orderinvoices.invoiceNumber} ${companyName}`;
      }
      return "#";
    },
    async selectedToPay(data) {
      this.orderData = data;
      this.getClinicPaymentDetails(data.customerId);
      this.pTypes = await this.fetchClinicsDetail(data.customerId)
      this.selectedToPayData.orderNumber = data.orderNumber
      this.selectedToPayData.total = data.total
    },
    populatePaymentDetails(val) {
      let searchResult = this.paymentMethods.filter(
        ({ paymentMethodId, type }) =>
          paymentMethodId === val && type === "CreditCard"
      );

      if (searchResult.length <= 0) {
        searchResult = this.paymentMethods.filter(
          ({ cardId, type }) => cardId === val && type === "CreditCard"
        );
      }

      if (searchResult.length <= 0) {
        searchResult = this.paymentMethods.filter(
          ({ paymentMethodId, type }) =>
            paymentMethodId === val && type === "BankAccount"
        );
      }

      if (searchResult.length) {
        this.paymentDetails = { ...searchResult[0] };
      }

      this.paymentType = null;
    },
    async retryOrder(data = {}) {
      this.$vs.loading();
      await this.retryPayment({
        orderId: this.orderData._id,
        ...this.paymentDetails
      })
        .then((res) => {
          this.isRepayment = false;
          this.popUpPay = false;
          this.showAddPayment = false;
          this.$vs.loading.close();
          this.getOrderedProductList();
          this.$vs.notify({
            title: "Retry Payment",
            text: "Successful",
            color: "success",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          this.dataTableParams.limit = 10;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: err.data.message,
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    getStatus(item) {
      if (item.isPaymentTerms && item.status !== "Paid"){
        return "Payment Terms"
      }
      return item.status;
    },
    getStatusDisplay(item) {
      if (item.isPaymentTerms && item.status !== "Paid"){
        return `<div class="text-lg">Status: Payment Terms</div>`
      }
    },
    getOrderedProductList() {
      this.$vs.loading();
      this.users = [];
      if (this.$route.params.clinicId) {
        this.fetchOrderedList({
          ...this.dataTableParams,
          clinicId: this.$route.params.clinicId,
        }).then((res) => {
          this.$vs.loading.close();
          this.users = res.data.data.docs;
          this.totalDocs = res.data.data.pagination.total;
          this.page = res.data.data.pagination.page;
          this.serverResponded = true;

        }).finally(() => {
          this.noDataText = "No Ordered Item Available"
          this.$vs.loading.close();
        });
      } else {
        this.fetchOrderedList(this.dataTableParams).then((res) => {
          this.$vs.loading.close();
          this.users = res.data.data.docs;
          this.totalDocs = res.data.data.pagination.total;
          this.page = res.data.data.pagination.page;
          this.serverResponded = true;
        }).finally(() => {
          this.noDataText = "No Ordered Item Available"
          this.$vs.loading.close();
        });
      }
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getOrderedProductList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getOrderedProductList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getOrderedProductList();
    },
    editDetailHandler(id) {
      this.$router.push({ name: this.viewRoute, params: { doctorId: id } });
    },
    viewDetailHandler({ _id: id, clinic }) {
      this.$router.push({
        name: this.getRoleRouteName("OrderDetail"),
        params: { orderId: id },
        query: { clinicId: clinic._idd }
      });
    },
    createDoctorHandler() {
      this.$router.push({ name: this.createRoute });
    },
    displayed(type) {
      let arr = this.pTypes.data.data.paymentTypes.map(types => types.toLowerCase())
      if(type === 'BankAccount') {
        return arr.includes('debitcard')
      }
      return arr.includes(type.toLowerCase())
    }
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  mounted() {
    this.stripe = window.Stripe(
      process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    );
    this.stripe
      .confirmPaymentIntent('pi_3MggKFFTI8zXgYFK0d4SYq2E_secret_lhinMUKMzWcVbHDRYI9JFZbtY', {
        payment_method: 'pm_1MggK4FTI8zXgYFKDicr0lZs',
        return_url: 'http://localhost:8080',
      })
      .then(function(result) {
        // Handle result.error or result.paymentIntent
      });
  },
  watch: {
    loading: function(isLoading) {
      if (isLoading) {
        return this.$vs.loading();
      }
      this.$vs.loading.close();
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        // this.getDoctorsList();
        this.getOrderedProductList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        // this.getDoctorsList();
        this.getOrderedProductList();
      }
    },
    "dataTableParams.filter": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.filter = newfilterVal;
        // this.getDoctorsList();
        this.getOrderedProductList();
      }
    },
    showAddPayment: function (current, prev) {
      if (!current && this.showFlag) {
        this.popUpPay = true;
      }
      this.paymentDetails = null;
      if (!current) {
        this.populatePaymentDetails(this.selectedPayment);
      }

      let payment = [];
      this.pTypes.data.data.paymentTypes.map(types => {
        payment.push(types.toLowerCase())
      })

      if (payment.includes('creditcard') && payment.includes('debitcard')) {
          this.showBankAccount = true;
          this.showCreditCard = true;
      } else if (payment.includes('creditcard') && !payment.includes('debitcard')) {
          this.showBankAccount = false;
          this.showCreditCard = true;
      } else if (!payment.includes('creditcard') && payment.includes('debitcard')) {
          this.showBankAccount = true;
          this.showCreditCard = false;
      } else {
          this.showBankAccount = false;
          this.showCreditCard = false;
      }
    },
    selectedPayment: function (val) {
      this.populatePaymentDetails(val);
    },
    "$store.state.clinicId": function () {
      this.getOrderedProductList();
    },
  },
  created() {
    this.getOrderedProductList();
    this.getUserDetail();
    if (
      this.$store.state.AppActiveUser.paymentDetails &&
      this.$store.state.AppActiveUser.paymentDetails.customerId &&
      this.$store.state.AppActiveUser.paymentDetails.mandate &&
      this.$store.state.AppActiveUser.paymentDetails.mandate.payment_method &&
      this.$store.state.AppActiveUser.paymentDetails.mandate.id
    ) {
      this.paymentType = "SavedClinicBankAccount";
    }
  },
};
</script>

<style lang="scss" scoped>
.text-pd-green {
  color: #3e695c;
}

.customClinicStyle {
  width: 200px;
  z-index: 999;
}

.vs-button-success.vs-button-flat {
    background: rgba(var(--vs-success),.08)!important;
}

.disabled {
  pointer-events: none;
  cursor: default;
  background: #f0f0f0;
  color: #919191;
}

.downloadLink, td, th {
  white-space: nowrap;
}

td:first-child{
  white-space: normal;
  min-width: 230px;
}


.mobileBtn {
  border: 1px solid #84cdb8;
  border-radius: 6px;
  background: transparent;
  font-size: 16px;
  padding: 9px 6px;
  text-align: center;
  line-height: 1;
  font-weight: 500;
  color: #90d2bf;

  &.disabled {
    border-color: #ddd;
    color: #ddd;
    flex: 1;
  }
}

.modalContent {
  overflow: auto;
  height: auto;
  max-height: 70vh;
}

</style>

<style lang="scss">
 @media screen and (max-width: 720px) {
  .custom-tab {
    .sr-root {
      padding-top: 8px !important;
      overflow: hidden !important;
      min-width: 320px !important;
    }
    .sr-main {
      padding: 2rem 1rem !important;
      min-width: 320px !important;
      height: 80vh !important;
      justify-content: start;
      overflow-y: auto !important;
      overflow-x: hidden !important;
    }
  }
 }
</style>
