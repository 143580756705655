<template>
  <div class="lg:mt-3 mt-8">
    <div class="text-2xl flex items-center justify-start md:gap-5 my-4 lg:hidden title font-semibold">
      <p>My Orders</p>
      <Clinic :onChange="() => { }" :title="''" />
    </div>
    <ClinicOrderList viewDetailRoute="OrderDetail"></ClinicOrderList>
  </div>
</template>

<script>
import ClinicOrderList from "../../../components/org-owner/order/ClinicOrderList";
import Clinic from "../../../components/shop/clinic.vue";
export default {
  name: "OrderList",
  components: { ClinicOrderList, Clinic },
};
</script>

<style scoped>
.title {
  margin-top: -30px !important;
}
</style>
